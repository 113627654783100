import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Heading,
  Text,
  Box,
  Image,
  AspectRatio,
} from '@chakra-ui/react';
import { Post } from '../services/postService';
import ViewCodeBlock from './blocks/ViewCodeBlock';

interface PreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  post: Post;
  onConfirm: () => void;
}

const PreviewModal: React.FC<PreviewModalProps> = ({ isOpen, onClose, post, onConfirm }) => {
  const renderBlock = (block: Post['blocks'][0], index: number) => {
    switch (block.type) {
      case 'text':
        return (
          <Box key={block.id} mb={4}>
            {block.title && <Heading as="h3" size="md" mb={2}>{block.title}</Heading>}
            <Text dangerouslySetInnerHTML={{ __html: block.content }} />
          </Box>
        );
      case 'code':
        return (
          <Box key={block.id} mb={4}>
            {block.title && <Heading as="h3" size="md" mb={2}>{block.title}</Heading>}
            <ViewCodeBlock
              content={block.content}
              defaultToOutput={false}
            />
            {block.caption && <Text fontSize="sm" fontStyle="italic" mt={2}>{block.caption}</Text>}
          </Box>
        );
      case 'url':
        return (
          <Box key={block.id} mb={4}>
            {block.title && <Heading as="h3" size="md" mb={2}>{block.title}</Heading>}
            {block.content.includes('youtube.com') || block.content.includes('youtu.be') ? (
              <AspectRatio ratio={16 / 9}>
                <iframe
                  src={`https://www.youtube.com/embed/${block.content.split('v=')[1] || block.content.split('/').pop()}`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </AspectRatio>
            ) : (
              <Box as="a" href={block.content} target="_blank" rel="noopener noreferrer">
                {block.content}
              </Box>
            )}
            {block.caption && <Text fontSize="sm" fontStyle="italic" mt={2}>{block.caption}</Text>}
          </Box>
        );
      case 'attachment':
        return (
          <Box key={block.id} mb={4}>
            {block.title && <Heading as="h3" size="md" mb={2}>{block.title}</Heading>}
            <Box as="a" href={block.content} target="_blank" rel="noopener noreferrer">
              Attachment {index + 1}
            </Box>
            {block.caption && <Text fontSize="sm" fontStyle="italic" mt={2}>{block.caption}</Text>}
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Preview Your Post</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6} align="stretch">
            <Heading as="h1" size="2xl">
              {post.title}
            </Heading>
            {post.coverImage && (
              <Image src={post.coverImage} alt="Cover" maxHeight="400px" objectFit="cover" />
            )}
            <Text fontSize="sm">
              Tags: {post.tags.join(', ')}
            </Text>
            {post.blocks.map((block, index) => renderBlock(block, index))}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onConfirm}>
            Publish
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PreviewModal;