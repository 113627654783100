import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface TextBlockProps {
  content: string;
  onChange: (content: string) => void;
}

const TextBlock: React.FC<TextBlockProps> = ({ content, onChange }) => {
  return (
    <ReactQuill
      value={content}
      onChange={onChange}
      placeholder="Tell your story..."
      modules={{
        toolbar: [
          [{ 'header': [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}],
          ['link', 'image'],
          ['clean']
        ],
      }}
    />
  );
};

export default TextBlock;