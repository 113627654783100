import React, { useState, useEffect } from 'react';
import { Box, Input, Text, VStack, Link } from '@chakra-ui/react';
import { AttachmentIcon } from '@chakra-ui/icons';

interface AttachmentBlockProps {
  content: string;
  onChange: (file: File) => void;
  title?: string;
  caption?: string;
}

const AttachmentBlock: React.FC<AttachmentBlockProps> = ({ content, onChange, title, caption }) => {
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (content) {
      const parts = content.split('/');
      setFileName(parts[parts.length - 1]);
    }
  }, [content]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFileName(file.name);
      onChange(file);
    }
  };

  return (
    <VStack align="stretch" spacing={2}>
      {title && <Text fontWeight="bold">{title}</Text>}
      <Input
        type="file"
        onChange={handleFileChange}
      />
      {fileName && (
        <Box>
          <Link href={content} isExternal>
            <AttachmentIcon mr={2} />
            {fileName || 'Download Attachment'}
          </Link>
        </Box>
      )}
      {caption && <Text fontSize="sm" fontStyle="italic">{caption}</Text>}
    </VStack>
  );
};

export default AttachmentBlock;