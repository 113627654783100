import React, { useState, useEffect } from 'react';
import { Box, Input, Text, VStack, AspectRatio } from '@chakra-ui/react';

interface UrlBlockProps {
  content: string;
  onChange: (content: string) => void;
  title?: string;
  caption?: string;
}

const UrlBlock: React.FC<UrlBlockProps> = ({ content, onChange, title, caption }) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(content);
  }, [content]);

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
    onChange(event.target.value);
  };

  const renderEmbed = () => {
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      const videoId = url.split('v=')[1] || url.split('/').pop();
      return (
        <AspectRatio ratio={16 / 9}>
          <iframe
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </AspectRatio>
      );
    } else if (url.includes('twitter.com')) {
      return (
        <Box>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        </Box>
      );
    } else {
      return (
        <Box as="a" href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </Box>
      );
    }
  };

  return (
    <VStack align="stretch" spacing={2}>
      {title && <Text fontWeight="bold">{title}</Text>}
      <Input
        value={url}
        onChange={handleUrlChange}
        placeholder="Enter URL (e.g., YouTube video, tweet, or any other link)"
      />
      {renderEmbed()}
      {caption && <Text fontSize="sm" fontStyle="italic">{caption}</Text>}
    </VStack>
  );
};

export default UrlBlock;