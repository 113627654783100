import React, { useState, useEffect, useRef } from 'react';
import { Box, IconButton, Text, VStack, HStack, useColorMode, Tabs, TabList, TabPanels, Tab, TabPanel, Tooltip, Icon, Switch, Spinner } from '@chakra-ui/react';
import { RepeatIcon, WarningIcon, CopyIcon, createIcon } from '@chakra-ui/icons';
import Editor from "@monaco-editor/react";

// Create a custom play icon
const PlayIcon = createIcon({
  displayName: "PlayIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      fill="currentColor"
      d="M8 5v14l11-7z"
    />
  ),
});

interface CodeBlockProps {
  content: string;
  onChange: (content: string) => void;
  title?: string;
  caption?: string;
}

const CodeBlock: React.FC<CodeBlockProps> = ({ content, onChange, title, caption }) => {
  const [code, setCode] = useState('');
  const { colorMode } = useColorMode();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [iframeKey, setIframeKey] = useState(0);
  const [defaultToOutput, setDefaultToOutput] = useState(false);
  const [showCodeOnly, setShowCodeOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    try {
      const parsedContent = JSON.parse(content);
      setCode(parsedContent.code || '');
      setDefaultToOutput(parsedContent.defaultToOutput || false);
      setShowCodeOnly(parsedContent.showCodeOnly || false);
    } catch {
      setCode(content);
    }
    setIsLoading(false);
  }, [content]);

  useEffect(() => {
    setActiveTab(defaultToOutput && !showCodeOnly ? 1 : 0);
  }, [defaultToOutput, showCodeOnly]);

  const handleCodeChange = (value: string | undefined) => {
    if (value !== undefined) {
      setCode(value);
      onChange(JSON.stringify({ code: value, defaultToOutput, showCodeOnly }));
    }
  };

  const clearOutput = () => {
    setIframeKey(prevKey => prevKey + 1);
    setError(null);
  };

  const handleRunCode = () => {
    setActiveTab(1);
    setIframeKey(prevKey => prevKey + 1);
    setError(null);
  };

  const handleIframeError = (event: Event) => {
    const iframe = event.target as HTMLIFrameElement;
    if (iframe.contentDocument) {
      const errorText = iframe.contentDocument.body.textContent;
      setError(errorText || 'An error occurred while executing the code.');
    }
  };

  const handleDefaultToOutputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDefaultToOutput(event.target.checked);
    onChange(JSON.stringify({ code, defaultToOutput: event.target.checked, showCodeOnly }));
  };

  const handleShowCodeOnlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowCodeOnly(event.target.checked);
    onChange(JSON.stringify({ code, defaultToOutput, showCodeOnly: event.target.checked }));
  };

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener('error', handleIframeError);
      return () => {
        iframe.removeEventListener('error', handleIframeError);
      };
    }
  }, [iframeKey]);

  const iframeContent = `
    <!DOCTYPE html>
    <html>
      <head>
        <style>
          body { font-family: Arial, sans-serif; }
        </style>
        <script>
          window.onerror = function(message, source, lineno, colno, error) {
            document.body.innerHTML += '<div style="color: red; white-space: pre-wrap;">' + error.stack + '</div>';
            return true;
          };
          console.log = function(...args) {
            document.body.innerHTML += '<div style="white-space: pre-wrap;">console.log: ' + args.join(' ') + '</div>';
          };
        </script>
        <script src="https://d3js.org/d3.v7.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/chart.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/three.js/r128/three.min.js"></script>
      </head>
      <body>
        ${code}
      </body>
    </html>
  `;

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="300px">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box borderWidth={1} borderRadius="0" p={4} bg={colorMode === 'dark' ? 'black' : 'white'} color={colorMode === 'dark' ? 'white' : 'black'}>
      <VStack align="stretch" spacing={4}>
        <HStack justifyContent="space-between">
          <Text fontWeight="bold">Code Block</Text>
          <HStack>
            <Text fontSize="sm">Show Code Only</Text>
            <Switch 
              isChecked={showCodeOnly} 
              onChange={handleShowCodeOnlyChange}
              size="sm"
            />
          </HStack>
          {!showCodeOnly && (
            <HStack>
              <Text fontSize="sm">Show Output by Default</Text>
              <Switch 
                isChecked={defaultToOutput} 
                onChange={handleDefaultToOutputChange}
                size="sm"
              />
            </HStack>
          )}
        </HStack>
        {!showCodeOnly && (
          <Tabs index={activeTab} onChange={setActiveTab} variant="unstyled">
            <TabList>
              <Tab _selected={{ borderBottom: "2px solid", borderColor: colorMode === 'dark' ? 'white' : 'black' }}>Code</Tab>
              <Tab _selected={{ borderBottom: "2px solid", borderColor: colorMode === 'dark' ? 'white' : 'black' }}>Output</Tab>
            </TabList>
            <TabPanels>
              <TabPanel padding={0}>
                <Editor
                  height="300px"
                  defaultLanguage="html"
                  value={code}
                  onChange={handleCodeChange}
                  theme={colorMode === 'dark' ? 'vs-dark' : 'light'}
                  options={{
                    minimap: { enabled: false },
                    fontSize: 14,
                  }}
                  loading={<Spinner size="xl" />}
                />
              </TabPanel>
              <TabPanel padding={0}>
                <Box borderWidth={1} p={2} borderRadius="0" bg={colorMode === 'dark' ? 'gray.800' : 'white'}>
                  <iframe
                    ref={iframeRef}
                    key={iframeKey}
                    title="Code Output"
                    width="100%"
                    height="300px"
                    style={{ border: 'none', backgroundColor: 'white' }}
                    sandbox="allow-scripts allow-popups allow-same-origin"
                    srcDoc={iframeContent}
                  />
                </Box>
                {error && (
                  <Box mt={2} p={2} bg="red.100" color="red.800" borderRadius="0">
                    <HStack>
                      <Icon as={WarningIcon} />
                      <Text fontWeight="bold">Error:</Text>
                    </HStack>
                    <Text mt={1}>{error}</Text>
                  </Box>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
        {showCodeOnly && (
          <Editor
            height="300px"
            defaultLanguage="html"
            value={code}
            onChange={handleCodeChange}
            theme={colorMode === 'dark' ? 'vs-dark' : 'light'}
            options={{
              minimap: { enabled: false },
              fontSize: 14,
              readOnly: true,
            }}
            loading={<Spinner size="xl" />}
          />
        )}
        <HStack>
          {!showCodeOnly && (
            <>
              <Tooltip label="Run Code">
                <IconButton
                  aria-label="Run Code"
                  icon={<PlayIcon />}
                  onClick={handleRunCode}
                  variant="outline"
                />
              </Tooltip>
              <Tooltip label="Clear Output">
                <IconButton
                  aria-label="Clear Output"
                  icon={<RepeatIcon />}
                  onClick={clearOutput}
                  variant="outline"
                />
              </Tooltip>
            </>
          )}
          <Tooltip label="Copy Code">
            <IconButton
              aria-label="Copy Code"
              icon={<CopyIcon />}
              onClick={() => navigator.clipboard.writeText(code)}
              variant="outline"
            />
          </Tooltip>
        </HStack>
      </VStack>
    </Box>
  );
};

export default CodeBlock;