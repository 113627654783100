import React, { useEffect, useState } from 'react';
import { Box, Text, VStack, Link, Spinner, Tabs, TabList, TabPanels, Tab, TabPanel, useColorModeValue, Alert, AlertIcon, Image, Flex } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { getRecentPosts, getPopularPosts, getFeaturedPosts, Post } from '../services/postService';
import InfiniteScroll from 'react-infinite-scroll-component';

const POSTS_PER_PAGE = 5;
const MAX_TITLE_LENGTH = 60;

const Home: React.FC = () => {
  const [featuredPosts, setFeaturedPosts] = useState<Post[]>([]);
  const [popularPosts, setPopularPosts] = useState<Post[]>([]);
  const [newestPosts, setNewestPosts] = useState<Post[]>([]);
  const [oldestPosts, setOldestPosts] = useState<Post[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState({
    featured: true,
    popular: true,
    newest: true,
    oldest: true,
  });

  const bgColor = useColorModeValue('white', 'black');
  const textColor = useColorModeValue('black', 'white');
  const subtleTextColor = useColorModeValue('gray.600', 'gray.400');
  const popularityColor = useColorModeValue('blue.600', 'blue.300');

  useEffect(() => {
    fetchInitialPosts();
  }, []);

  const fetchInitialPosts = async () => {
    try {
      setIsLoading(true);
      const featured = await getFeaturedPosts(POSTS_PER_PAGE);
      const popular = await getPopularPosts(POSTS_PER_PAGE);
      const newest = await getRecentPosts(POSTS_PER_PAGE);
      const oldest = await getRecentPosts(POSTS_PER_PAGE, true);

      setFeaturedPosts(featured);
      setPopularPosts(popular);
      setNewestPosts(newest);
      setOldestPosts(oldest);

      setHasMore({
        featured: featured.length === POSTS_PER_PAGE,
        popular: popular.length === POSTS_PER_PAGE,
        newest: newest.length === POSTS_PER_PAGE,
        oldest: oldest.length === POSTS_PER_PAGE,
      });
    } catch (error) {
      console.error('Error fetching posts:', error);
      setError('An error occurred while fetching posts. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMorePosts = async (type: 'featured' | 'popular' | 'newest' | 'oldest') => {
    if (!hasMore[type]) return;

    try {
      let newPosts: Post[] = [];
      let currentPosts: Post[] = [];
      switch (type) {
        case 'featured':
          currentPosts = featuredPosts;
          newPosts = await getFeaturedPosts(POSTS_PER_PAGE);
          break;
        case 'popular':
          currentPosts = popularPosts;
          newPosts = await getPopularPosts(POSTS_PER_PAGE);
          break;
        case 'newest':
          currentPosts = newestPosts;
          newPosts = await getRecentPosts(POSTS_PER_PAGE);
          break;
        case 'oldest':
          currentPosts = oldestPosts;
          newPosts = await getRecentPosts(POSTS_PER_PAGE, true);
          break;
      }

      const uniqueNewPosts = newPosts.filter(newPost => !currentPosts.some(currentPost => currentPost.id === newPost.id));

      if (uniqueNewPosts.length > 0) {
        switch (type) {
          case 'featured':
            setFeaturedPosts(prev => [...prev, ...uniqueNewPosts]);
            break;
          case 'popular':
            setPopularPosts(prev => [...prev, ...uniqueNewPosts]);
            break;
          case 'newest':
            setNewestPosts(prev => [...prev, ...uniqueNewPosts]);
            break;
          case 'oldest':
            setOldestPosts(prev => [...prev, ...uniqueNewPosts]);
            break;
        }
      }

      setHasMore(prev => ({ ...prev, [type]: uniqueNewPosts.length > 0 }));
    } catch (error) {
      console.error('Error fetching more posts:', error);
      setError('An error occurred while fetching more posts. Please try again later.');
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        {error}
      </Alert>
    );
  }

  const truncateTitle = (title: string) => {
    if (title.length <= MAX_TITLE_LENGTH) return title;
    return title.substring(0, MAX_TITLE_LENGTH) + '...';
  };

  const formatDate = (date: Date | { seconds: number; nanoseconds: number } | null) => {
    if (!date) return 'Date not available';
    if (date instanceof Date) return date.toLocaleDateString();
    if ('seconds' in date) return new Date(date.seconds * 1000).toLocaleDateString();
    return 'Invalid date';
  };

  const renderPostList = (posts: Post[]) => (
    <VStack spacing={6} align="stretch">
      {posts.length === 0 ? (
        <Text>No posts found.</Text>
      ) : (
        posts.map((post) => (
          <Box key={post.id} p={4} borderWidth="1px" borderRadius="lg" overflow="hidden">
            <Flex direction="row" alignItems="center">
              {post.coverImage && (
                <Image 
                  src={post.coverImage} 
                  alt={post.title} 
                  width="150px"
                  height="150px"
                  objectFit="cover" 
                  mr={4}
                  fallbackSrc="https://via.placeholder.com/150x150"
                />
              )}
              <Box>
                <Link as={RouterLink} to={`/post/${post.id}`}>
                  <Text fontSize="xl" fontWeight="bold">{truncateTitle(post.title)}</Text>
                </Link>
                <Text fontSize="sm" color={subtleTextColor} mt={2}>
                  By {post.authorName || 'Anonymous'} | {formatDate(post.createdAt)}
                </Text>
                {post.popularityScore !== undefined && (
                  <Text fontSize="sm" color={popularityColor} mt={1}>
                    Popularity: {post.popularityScore}
                  </Text>
                )}
              </Box>
            </Flex>
          </Box>
        ))
      )}
    </VStack>
  );

  return (
    <Box maxWidth="900px" margin="auto" mt={8} px={4} color={textColor} bg={bgColor}>
      <Tabs variant="unstyled">
        <TabList display="flex">
          {['SPOTLIGHT', 'POPULAR', 'NEWEST', 'OLDEST'].map((tabName) => (
            <Tab
              key={tabName}
              textTransform="uppercase"
              fontWeight="bold"
              flex={1}
              _selected={{
                color: bgColor,
                bg: textColor,
              }}
              _focus={{ boxShadow: 'none' }}
              borderRadius="0"
            >
              {tabName}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel>
            <InfiniteScroll
              dataLength={featuredPosts.length}
              next={() => fetchMorePosts('featured')}
              hasMore={hasMore.featured}
              loader={<Spinner />}
            >
              {renderPostList(featuredPosts)}
            </InfiniteScroll>
          </TabPanel>
          <TabPanel>
            <InfiniteScroll
              dataLength={popularPosts.length}
              next={() => fetchMorePosts('popular')}
              hasMore={hasMore.popular}
              loader={<Spinner />}
            >
              {renderPostList(popularPosts)}
            </InfiniteScroll>
          </TabPanel>
          <TabPanel>
            <InfiniteScroll
              dataLength={newestPosts.length}
              next={() => fetchMorePosts('newest')}
              hasMore={hasMore.newest}
              loader={<Spinner />}
            >
              {renderPostList(newestPosts)}
            </InfiniteScroll>
          </TabPanel>
          <TabPanel>
            <InfiniteScroll
              dataLength={oldestPosts.length}
              next={() => fetchMorePosts('oldest')}
              hasMore={hasMore.oldest}
              loader={<Spinner />}
            >
              {renderPostList(oldestPosts)}
            </InfiniteScroll>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Home;