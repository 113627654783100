import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDjUaaoHaToRGC8OSytJwGVdkkfAoXuzF8",
  authDomain: "building-blocks-4e605.firebaseapp.com",
  projectId: "building-blocks-4e605",
  storageBucket: "building-blocks-4e605.appspot.com",
  messagingSenderId: "508080046541",
  appId: "1:508080046541:web:33ce2444da437f59dd0484",
  measurementId: "G-GF64SP1M46"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase services
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const googleProvider = new GoogleAuthProvider();

export { analytics };
export default app;