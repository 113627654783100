import React, { useState, useRef, useEffect } from 'react';
import { Box, Input, Button, VStack, HStack, useToast, IconButton, Heading, Divider, Image, Text, FormControl, FormErrorMessage, useColorMode, Spinner, Textarea, useDisclosure, Tag, TagLabel, TagCloseButton } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth, AuthUser } from '../hooks/useAuth';
import { ChevronUpIcon, ChevronDownIcon, DeleteIcon, EditIcon, AttachmentIcon, LinkIcon, AddIcon } from '@chakra-ui/icons';
import { FaCode } from 'react-icons/fa';
import TextBlock from '../components/blocks/TextBlock';
import CodeBlock from '../components/blocks/CodeBlock';
import UrlBlock from '../components/blocks/UrlBlock';
import AttachmentBlock from '../components/blocks/AttachmentBlock';
import { createPost, getPost, updatePost, saveDraft, publishPost, Block, Post } from '../services/postService';
import { uploadImage, uploadFile } from '../services/storageService';
import { Timestamp } from 'firebase/firestore';
import PreviewModal from '../components/PreviewModal';

const Write: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [post, setPost] = useState<Partial<Post>>({
    title: '',
    blocks: [{ id: '1', type: 'text', content: '', order: 0 }],
    published: false,
    tags: [],
    coverImage: '',
  });
  const [currentTag, setCurrentTag] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [lastSaved, setLastSaved] = useState<Date | null>(null);
  const [errors, setErrors] = useState<{ title?: string; blocks?: string; tags?: string; coverImage?: string }>({});
  const { user } = useAuth() as AuthUser;
  const navigate = useNavigate();
  const toast = useToast();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchPost = async () => {
      if (id && user) {
        try {
          const fetchedPost = await getPost(id);
          if (fetchedPost && fetchedPost.authorId === user.uid) {
            setPost(fetchedPost);
          }
        } catch (error) {
          console.error('Error fetching post:', error);
          toast({
            title: 'Error',
            description: 'Failed to fetch the post. Please try again.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      }
    };

    fetchPost();
  }, [id, user, toast]);

  useEffect(() => {
    const autoSaveDraft = async () => {
      if (user && post.title && post.blocks && post.blocks.length > 0 && post.coverImage) {
        setIsSaving(true);
        try {
          const currentTime = Timestamp.now();
          const draftData: Omit<Post, 'id'> = {
            title: post.title,
            blocks: post.blocks,
            authorId: user.uid,
            published: false,
            createdAt: post.createdAt || currentTime,
            updatedAt: currentTime,
            coverImage: post.coverImage,
            tags: post.tags || [],
          };

          if (id) {
            await updatePost(id, draftData);
          } else {
            const newId = await saveDraft(draftData);
            navigate(`/write/${newId}`, { replace: true });
          }
          setLastSaved(new Date());
        } catch (error) {
          console.error('Error auto-saving draft:', error);
        } finally {
          setIsSaving(false);
        }
      }
    };

    const timer = setTimeout(autoSaveDraft, 30000); // Auto-save every 30 seconds

    return () => clearTimeout(timer);
  }, [post, user, id, navigate]);

  const validateInputs = () => {
    const newErrors: { title?: string; blocks?: string; tags?: string; coverImage?: string } = {};
    if (!post.title?.trim()) {
      newErrors.title = 'Title is required';
    }
    if (!post.blocks || post.blocks.length === 0 || post.blocks.every(block => !block.content.trim())) {
      newErrors.blocks = 'At least one non-empty block is required';
    }
    if (!post.tags || post.tags.length === 0) {
      newErrors.tags = 'At least one tag is required';
    }
    if (!post.coverImage) {
      newErrors.coverImage = 'Cover image is required';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddBlock = (type: Block['type']) => {
    const newBlock: Block = { id: Date.now().toString(), type, content: '', order: post.blocks ? post.blocks.length : 0 };
    setPost(prev => ({ ...prev, blocks: [...(prev.blocks || []), newBlock] }));
  };

  const handleBlockChange = (index: number, updatedBlock: Partial<Block>) => {
    setPost(prev => {
      const newBlocks = [...(prev.blocks || [])];
      newBlocks[index] = { ...newBlocks[index], ...updatedBlock };
      return { ...prev, blocks: newBlocks };
    });
    setErrors(prev => ({ ...prev, blocks: undefined }));
  };

  const handleMoveBlock = (index: number, direction: 'up' | 'down') => {
    if (!post.blocks) return;
    if ((direction === 'up' && index === 0) || (direction === 'down' && index === post.blocks.length - 1)) {
      return;
    }
    const newBlocks = [...post.blocks];
    const [movedBlock] = newBlocks.splice(index, 1);
    newBlocks.splice(direction === 'up' ? index - 1 : index + 1, 0, movedBlock);
    newBlocks.forEach((block, i) => block.order = i);
    setPost(prev => ({ ...prev, blocks: newBlocks }));
  };

  const handleDeleteBlock = (index: number) => {
    setPost(prev => {
      const newBlocks = (prev.blocks || []).filter((_, i) => i !== index);
      newBlocks.forEach((block, i) => block.order = i);
      return { ...prev, blocks: newBlocks };
    });
  };

  const handleCoverImageUpload = async (file: File) => {
    if (!user) {
      console.error('User not authenticated');
      toast({
        title: 'Error',
        description: 'You must be logged in to upload images.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      console.log('Uploading cover image:', file.name);
      const url = await uploadImage(file, user.uid);
      console.log('Cover image uploaded successfully:', url);
      setPost(prev => ({ ...prev, coverImage: url }));
      setErrors(prev => ({ ...prev, coverImage: undefined }));
    } catch (error) {
      console.error('Error uploading cover image:', error);
      toast({
        title: 'Error',
        description: 'Failed to upload cover image. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handlePublish = async () => {
    if (!user) {
      console.error('User not authenticated');
      toast({
        title: 'Error',
        description: 'You must be logged in to create a post.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!validateInputs()) {
      console.error('Validation failed');
      toast({
        title: 'Error',
        description: 'Please fix the errors before publishing the post.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    onOpen(); // Open the preview modal
  };

  const handleConfirmPublish = async () => {
    setIsLoading(true);
    try {
      const currentTime = Timestamp.now();
      const postData: Omit<Post, 'id'> = {
        title: post.title || '',
        blocks: post.blocks || [],
        authorId: user!.uid,
        published: true,
        createdAt: post.createdAt || currentTime,
        updatedAt: currentTime,
        coverImage: post.coverImage || '',
        tags: post.tags || [],
      };

      console.log('Publishing post:', postData);

      let postId;
      if (id) {
        await updatePost(id, postData);
        await publishPost(id, user!.uid);
        postId = id;
        console.log('Post updated and published successfully:', postId);
      } else {
        postId = await createPost(postData);
        await publishPost(postId, user!.uid);
        console.log('Post created and published successfully:', postId);
      }

      toast({
        title: 'Success',
        description: 'Your post has been published.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      navigate(`/post/${postId}`);
    } catch (error) {
      console.error('Error publishing post:', error);
      toast({
        title: 'Error',
        description: 'Failed to publish post. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      onClose(); // Close the preview modal
    }
  };

  const handleSaveDraft = async () => {
    setIsLoading(true);
    try {
      const currentTime = Timestamp.now();
      const draftData: Omit<Post, 'id'> = {
        title: post.title || '',
        blocks: post.blocks || [],
        authorId: user!.uid,
        published: false,
        createdAt: post.createdAt || currentTime,
        updatedAt: currentTime,
        coverImage: post.coverImage || '',
        tags: post.tags || [],
      };

      console.log('Saving draft:', draftData);

      let postId;
      if (id) {
        await updatePost(id, draftData);
        postId = id;
        console.log('Draft updated successfully:', postId);
      } else {
        postId = await saveDraft(draftData);
        console.log('Draft saved successfully:', postId);
      }

      toast({
        title: 'Success',
        description: 'Your draft has been saved.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      navigate('/drafts');
    } catch (error) {
      console.error('Error saving draft:', error);
      toast({
        title: 'Error',
        description: 'Failed to save draft. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddTag = () => {
    if (currentTag.trim() && !post.tags?.includes(currentTag.trim())) {
      setPost(prev => ({
        ...prev,
        tags: [...(prev.tags || []), currentTag.trim()]
      }));
      setCurrentTag('');
      setErrors(prev => ({ ...prev, tags: undefined }));
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    setPost(prev => ({
      ...prev,
      tags: prev.tags?.filter(tag => tag !== tagToRemove) || []
    }));
  };

  const renderBlock = (block: Block, index: number) => {
    return (
      <Box key={block.id} borderWidth={1} borderRadius="md" p={4} mb={4}>
        <HStack align="start" spacing={4}>
          <VStack spacing={2}>
            <IconButton
              aria-label="Move block up"
              icon={<ChevronUpIcon />}
              size="sm"
              variant="ghost"
              onClick={() => handleMoveBlock(index, 'up')}
              isDisabled={index === 0}
            />
            <IconButton
              aria-label="Move block down"
              icon={<ChevronDownIcon />}
              size="sm"
              variant="ghost"
              onClick={() => handleMoveBlock(index, 'down')}
              isDisabled={index === (post.blocks?.length || 0) - 1}
            />
            <IconButton
              aria-label="Delete block"
              icon={<DeleteIcon />}
              size="sm"
              variant="ghost"
              onClick={() => handleDeleteBlock(index)}
            />
          </VStack>
          <Box flex={1}>
            <Input
              placeholder="Block Title (optional)"
              value={block.title || ''}
              onChange={(e) => handleBlockChange(index, { title: e.target.value })}
              mb={2}
            />
            {block.type === 'text' && (
              <TextBlock
                content={block.content}
                onChange={(content) => handleBlockChange(index, { content })}
              />
            )}
            {block.type === 'code' && (
              <CodeBlock
                content={block.content}
                onChange={(content) => handleBlockChange(index, { content })}
              />
            )}
            {block.type === 'url' && (
              <UrlBlock
                content={block.content}
                onChange={(content) => handleBlockChange(index, { content })}
              />
            )}
            {block.type === 'attachment' && (
              <AttachmentBlock
                content={block.content}
                onChange={async (file) => {
                  if (user) {
                    try {
                      console.log('Uploading attachment:', file.name);
                      const url = await uploadFile(file, user.uid);
                      console.log('Attachment uploaded successfully:', url);
                      handleBlockChange(index, { content: url });
                    } catch (error) {
                      console.error('Error uploading attachment:', error);
                      toast({
                        title: 'Error',
                        description: 'Failed to upload attachment. Please try again.',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                      });
                    }
                  }
                }}
              />
            )}
            {(block.type === 'code' || block.type === 'url' || block.type === 'attachment') && (
              <Textarea
                placeholder="Caption (optional)"
                value={block.caption || ''}
                onChange={(e) => handleBlockChange(index, { caption: e.target.value })}
                mt={2}
              />
            )}
          </Box>
        </HStack>
      </Box>
    );
  };

  return (
    <Box maxWidth="800px" margin="auto" mt={8} px={4}>
      <Heading as="h1" size="2xl" mb={6}>WRITE YOUR STORY</Heading>
      <VStack spacing={6} align="stretch">
        <FormControl isInvalid={!!errors.title}>
          <Input
            placeholder="Enter your story title"
            value={post.title || ''}
            onChange={(e) => {
              setPost(prev => ({ ...prev, title: e.target.value }));
              setErrors(prev => ({ ...prev, title: undefined }));
            }}
            size="lg"
            fontSize="3xl"
            fontWeight="bold"
            border="none"
            _focus={{ boxShadow: 'none' }}
            isDisabled={isLoading}
          />
          <FormErrorMessage>{errors.title}</FormErrorMessage>
        </FormControl>
        <Divider borderColor={colorMode === 'light' ? 'black' : 'white'} borderWidth="2px" mt={2} />
        <FormControl isInvalid={!!errors.tags}>
          <HStack>
            <Input
              placeholder="Add a tag"
              value={currentTag}
              onChange={(e) => setCurrentTag(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleAddTag();
                }
              }}
              size="md"
            />
            <IconButton
              aria-label="Add tag"
              icon={<AddIcon />}
              onClick={handleAddTag}
              isDisabled={!currentTag.trim()}
            />
          </HStack>
          <HStack mt={2} flexWrap="wrap">
            {post.tags?.map((tag, index) => (
              <Tag key={index} size="md" borderRadius="full" variant="solid" colorScheme="blue">
                <TagLabel>{tag}</TagLabel>
                <TagCloseButton onClick={() => handleRemoveTag(tag)} />
              </Tag>
            ))}
          </HStack>
          <FormErrorMessage>{errors.tags}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.coverImage}>
          <HStack>
            <Input
              placeholder="Cover image URL"
              value={post.coverImage || ''}
              onChange={(e) => {
                setPost(prev => ({ ...prev, coverImage: e.target.value }));
                setErrors(prev => ({ ...prev, coverImage: undefined }));
              }}
              size="md"
            />
            <Input
              type="file"
              accept="image/*"
              onChange={(e) => e.target.files && handleCoverImageUpload(e.target.files[0])}
              display="none"
              ref={fileInputRef}
            />
            <Button onClick={() => fileInputRef.current?.click()}>
              Upload Image
            </Button>
          </HStack>
          <FormErrorMessage>{errors.coverImage}</FormErrorMessage>
        </FormControl>
        {post.coverImage && <Image src={post.coverImage} alt="Cover" maxHeight="200px" objectFit="cover" mt={4} />}
        <FormControl isInvalid={!!errors.blocks}>
          {post.blocks?.map((block, index) => renderBlock(block, index))}
          <FormErrorMessage>{errors.blocks}</FormErrorMessage>
        </FormControl>
        <HStack spacing={2} justifyContent="center">
          <IconButton
            aria-label="Add text block"
            icon={<EditIcon />}
            onClick={() => handleAddBlock('text')}
            variant="outline"
          />
          <IconButton
            aria-label="Add code block"
            icon={<FaCode />}
            onClick={() => handleAddBlock('code')}
            variant="outline"
          />
          <IconButton
            aria-label="Add URL block"
            icon={<LinkIcon />}
            onClick={() => handleAddBlock('url')}
            variant="outline"
          />
          <IconButton
            aria-label="Add attachment block"
            icon={<AttachmentIcon />}
            onClick={() => handleAddBlock('attachment')}
            variant="outline"
          />
        </HStack>
        <HStack spacing={4} justifyContent="center" align="center">
          <Button onClick={handleSaveDraft} colorScheme="gray" isLoading={isLoading}>
            SAVE DRAFT
          </Button>
          <Button onClick={handlePublish} colorScheme="blue" isLoading={isLoading}>
            PUBLISH
          </Button>
          {isSaving && <Spinner size="sm" />}
          {lastSaved && <Text fontSize="sm">Last saved: {lastSaved.toLocaleTimeString()}</Text>}
        </HStack>
      </VStack>
      <PreviewModal
        isOpen={isOpen}
        onClose={onClose}
        post={post as Post}
        onConfirm={handleConfirmPublish}
      />
    </Box>
  );
};

export default Write;