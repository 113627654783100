import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  VStack,
  Image,
  useColorModeValue,
  Grid,
  GridItem,
  Button,
  HStack,
  useToast,
  Flex,
  Badge,
  Divider,
  Link,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormErrorMessage,
  IconButton,
  Spinner,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { EditIcon, AddIcon, DeleteIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { useAuth } from '../hooks/useAuth';
import { getAuthorDetails, updateAuthorProfile, getUserPosts, getDrafts, getSubscribedAuthors, Post, PersonalLink, Author, deletePost } from '../services/postService';
import { uploadFile } from '../services/storageService';

const POSTS_PER_PAGE = 6;

const Profile: React.FC = () => {
  const { user, logout } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const [author, setAuthor] = useState<any>(null);
  const [posts, setPosts] = useState<Post[]>([]);
  const [drafts, setDrafts] = useState<Post[]>([]);
  const [subscribedAuthors, setSubscribedAuthors] = useState<Author[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editedAuthor, setEditedAuthor] = useState<any>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [postToDelete, setPostToDelete] = useState<Post | null>(null);

  const bgColor = useColorModeValue('white', 'black');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  useEffect(() => {
    const fetchAuthorAndPosts = async () => {
      if (user) {
        try {
          const authorData = await getAuthorDetails(user.uid);
          if (authorData) {
            setAuthor(authorData);
            setEditedAuthor(authorData);

            const authorPosts = await getUserPosts(user.uid);
            setPosts(authorPosts.slice(0, POSTS_PER_PAGE));
            setHasMore(authorPosts.length > POSTS_PER_PAGE);

            const authorDrafts = await getDrafts(user.uid);
            setDrafts(authorDrafts);

            const subscribedAuthorsData = await getSubscribedAuthors(user.uid);
            setSubscribedAuthors(subscribedAuthorsData);
          } else {
            // If author doesn't exist, create a new profile
            const newAuthor = {
              id: user.uid,
              displayName: user.displayName || 'New User',
              bio: '',
              profilePicture: '',
              personalLinks: [],
              joinDate: new Date(),
              totalPosts: 0,
              subscriberCount: 0
            };
            setAuthor(newAuthor);
            setEditedAuthor(newAuthor);
            setIsEditing(true); // Automatically enter edit mode for new users
          }
        } catch (error) {
          console.error('Error fetching author data:', error);
          toast({
            title: 'Error',
            description: 'Failed to load profile',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchAuthorAndPosts();
  }, [user, toast]);

  const loadMorePosts = async () => {
    if (user) {
      const nextPage = page + 1;
      const startIndex = (nextPage - 1) * POSTS_PER_PAGE;
      const endIndex = startIndex + POSTS_PER_PAGE;

      const newPosts = await getUserPosts(user.uid);
      const paginatedPosts = newPosts.slice(startIndex, endIndex);

      setPosts(prevPosts => [...prevPosts, ...paginatedPosts]);
      setPage(nextPage);
      setHasMore(endIndex < newPosts.length);
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      setEditedAuthor({ ...author });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setEditedAuthor({ ...editedAuthor, [name]: value });
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      try {
        const profilePictureUrl = await uploadFile(file, user!.uid);
        setEditedAuthor({ ...editedAuthor, profilePicture: profilePictureUrl });
      } catch (error) {
        console.error('Error uploading file:', error);
        toast({
          title: 'Error',
          description: 'Failed to upload profile picture',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleProfilePictureClick = () => {
    if (isEditing && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleAddPersonalLink = () => {
    const newLinks = [...(editedAuthor.personalLinks || []), { id: Date.now().toString(), description: '', url: '' }];
    setEditedAuthor({ ...editedAuthor, personalLinks: newLinks });
  };

  const handleRemovePersonalLink = (id: string) => {
    const newLinks = editedAuthor.personalLinks.filter((link: PersonalLink) => link.id !== id);
    setEditedAuthor({ ...editedAuthor, personalLinks: newLinks });
  };

  const handlePersonalLinkChange = (id: string, field: 'description' | 'url', value: string) => {
    const newLinks = editedAuthor.personalLinks.map((link: PersonalLink) =>
      link.id === id ? { ...link, [field]: value } : link
    );
    setEditedAuthor({ ...editedAuthor, personalLinks: newLinks });
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!editedAuthor.displayName.trim()) {
      newErrors.displayName = 'Display name is required';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      await updateAuthorProfile(user!.uid, editedAuthor);
      setAuthor(editedAuthor);
      setIsEditing(false);
      toast({
        title: 'Profile updated',
        description: 'Your profile has been successfully updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating profile:', error);
      toast({
        title: 'Error',
        description: 'Failed to update profile',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSignOut = async () => {
    try {
      await logout();
      // Redirect to home page or login page after signing out
      navigate('/');
    } catch (error) {
      console.error('Failed to sign out', error);
      toast({
        title: 'Error',
        description: 'Failed to sign out. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeletePost = async () => {
    if (!postToDelete) return;

    try {
      await deletePost(postToDelete.id);
      setPosts(posts.filter(post => post.id !== postToDelete.id));
      toast({
        title: "Post deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting post:', error);
      toast({
        title: "Error deleting post",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setPostToDelete(null);
      onClose();
    }
  };

  const openDeleteDialog = (post: Post) => {
    setPostToDelete(post);
    onOpen();
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (!author) {
    return <Box>Error loading profile. Please try again.</Box>;
  }

  return (
    <Box maxWidth="800px" margin="auto" mt={8} p={6} bg={bgColor} color={textColor} borderRadius="md" boxShadow="md">
      <VStack spacing={6} align="stretch">
        <Flex direction={{ base: 'column', md: 'row' }} alignItems="center" justifyContent="space-between">
          <HStack spacing={6} align="center" mb={{ base: 4, md: 0 }}>
            <Box position="relative" onClick={handleProfilePictureClick} cursor={isEditing ? 'pointer' : 'default'}>
              <Image
                src={editedAuthor?.profilePicture || 'https://via.placeholder.com/150'}
                alt={editedAuthor?.displayName}
                borderRadius="full"
                boxSize="150px"
                objectFit="cover"
              />
              {isEditing && (
                <IconButton
                  aria-label="Change profile picture"
                  icon={<EditIcon />}
                  size="sm"
                  colorScheme="blue"
                  position="absolute"
                  bottom="0"
                  right="0"
                />
              )}
              <Input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                display="none"
              />
            </Box>
            <VStack align="start" spacing={2}>
              {isEditing ? (
                <FormControl isInvalid={!!errors.displayName}>
                  <Input
                    name="displayName"
                    value={editedAuthor.displayName}
                    onChange={handleInputChange}
                    placeholder="Enter your display name"
                    size="lg"
                    fontWeight="bold"
                  />
                  <FormErrorMessage>{errors.displayName}</FormErrorMessage>
                </FormControl>
              ) : (
                <Heading as="h1" size="2xl">
                  {author.displayName}
                </Heading>
              )}
              <HStack>
                <Badge colorScheme="blue">Posts: {author.totalPosts}</Badge>
                <Badge colorScheme="green">Subscribers: {author.subscriberCount}</Badge>
              </HStack>
              <Text fontSize="sm" color="gray.500">
                Joined: {author.joinDate instanceof Date ? author.joinDate.toLocaleDateString() : new Date(author.joinDate.seconds * 1000).toLocaleDateString()}
              </Text>
            </VStack>
          </HStack>
          <Button
            onClick={handleEditToggle}
            colorScheme={isEditing ? 'red' : 'blue'}
            size="lg"
          >
            {isEditing ? 'Cancel' : 'Edit Profile'}
          </Button>
        </Flex>

        <Divider />

        {isEditing ? (
          <FormControl>
            <FormLabel>Bio</FormLabel>
            <Textarea
              name="bio"
              value={editedAuthor.bio}
              onChange={handleInputChange}
              placeholder="Tell us about yourself"
            />
          </FormControl>
        ) : author.bio ? (
          <Box>
            <Heading as="h2" size="md" mb={2}>
              About
            </Heading>
            <Text>{author.bio}</Text>
          </Box>
        ) : null}

        <Box>
          <Heading as="h2" size="md" mb={2}>
            Personal Links
          </Heading>
          <VStack align="start" spacing={2}>
            {isEditing ? (
              <>
                {editedAuthor.personalLinks?.map((link: PersonalLink) => (
                  <HStack key={link.id} width="100%">
                    <Input
                      placeholder="Description"
                      value={link.description}
                      onChange={(e) => handlePersonalLinkChange(link.id, 'description', e.target.value)}
                    />
                    <Input
                      placeholder="URL"
                      value={link.url}
                      onChange={(e) => handlePersonalLinkChange(link.id, 'url', e.target.value)}
                    />
                    <IconButton
                      aria-label="Remove link"
                      icon={<DeleteIcon />}
                      onClick={() => handleRemovePersonalLink(link.id)}
                    />
                  </HStack>
                ))}
                <Button leftIcon={<AddIcon />} onClick={handleAddPersonalLink} mt={2}>
                  Add Link
                </Button>
              </>
            ) : author.personalLinks && author.personalLinks.length > 0 ? (
              author.personalLinks.map((link: PersonalLink) => (
                <Link href={link.url} isExternal key={link.id}>
                  {link.description} <ExternalLinkIcon mx="2px" />
                </Link>
              ))
            ) : (
              <Text>No personal links added</Text>
            )}
          </VStack>
        </Box>

        {isEditing && (
          <Button colorScheme="blue" onClick={handleSubmit} mt={4}>
            Save Changes
          </Button>
        )}

        {!isEditing && (
          <>
            <Divider />

            <Heading as="h2" size="xl" mt={8}>
              Drafts
            </Heading>
            <Grid templateColumns="repeat(auto-fill, minmax(250px, 1fr))" gap={6}>
              {drafts.map((draft) => (
                <GridItem key={draft.id}>
                  <RouterLink to={`/write/${draft.id}`}>
                    <Box p={4} borderWidth={1} borderRadius="md" height="100%" borderColor={borderColor} _hover={{ boxShadow: 'md' }}>
                      <Heading as="h3" size="md" mb={2}>
                        {draft.title || 'Untitled Draft'}
                      </Heading>
                      <Text fontSize="sm" color="gray.500">
                        {new Date(draft.updatedAt.seconds * 1000).toLocaleDateString()}
                      </Text>
                    </Box>
                  </RouterLink>
                </GridItem>
              ))}
            </Grid>

            <Divider />

            <Heading as="h2" size="xl" mt={8}>
              Published Posts
            </Heading>
            <Grid templateColumns="repeat(auto-fill, minmax(250px, 1fr))" gap={6}>
              {posts.map((post) => (
                <GridItem key={post.id}>
                  <Box p={4} borderWidth={1} borderRadius="md" height="100%" borderColor={borderColor} _hover={{ boxShadow: 'md' }}>
                    <RouterLink to={`/post/${post.id}`}>
                      <Heading as="h3" size="md" mb={2}>
                        {post.title}
                      </Heading>
                      <Text fontSize="sm" color="gray.500">
                        {new Date(post.createdAt.seconds * 1000).toLocaleDateString()}
                      </Text>
                    </RouterLink>
                    <HStack mt={2} spacing={2}>
                      <Button
                        as={RouterLink}
                        to={`/write/${post.id}`}
                        size="sm"
                        colorScheme="blue"
                      >
                        Edit
                      </Button>
                      <IconButton
                        aria-label="Delete post"
                        icon={<DeleteIcon />}
                        size="sm"
                        colorScheme="red"
                        onClick={() => openDeleteDialog(post)}
                      />
                    </HStack>
                  </Box>
                </GridItem>
              ))}
            </Grid>
            {hasMore && (
              <Button onClick={loadMorePosts} mt={4} colorScheme="blue" width="full">
                Load More Posts
              </Button>
            )}

            <Divider />

            <Heading as="h2" size="xl" mt={8}>
              Subscribed Authors
            </Heading>
            <Grid templateColumns="repeat(auto-fill, minmax(250px, 1fr))" gap={6}>
              {subscribedAuthors.map((subscribedAuthor) => (
                <GridItem key={subscribedAuthor.id}>
                  <RouterLink to={`/author/${subscribedAuthor.id}`}>
                    <Box p={4} borderWidth={1} borderRadius="md" height="100%" borderColor={borderColor} _hover={{ boxShadow: 'md' }}>
                      <Heading as="h3" size="md" mb={2}>
                        {subscribedAuthor.displayName}
                      </Heading>
                      <Text fontSize="sm" color="gray.500">
                        Posts: {subscribedAuthor.totalPosts}
                      </Text>
                    </Box>
                  </RouterLink>
                </GridItem>
              ))}
            </Grid>
          </>
        )}

        <Button onClick={handleSignOut} colorScheme="red" mt={8}>
          Sign Out
        </Button>
      </VStack>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Post
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this post? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeletePost} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default Profile;