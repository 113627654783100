import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Box, Heading, Text, VStack, Spinner, useColorModeValue, Grid, GridItem, AspectRatio, Button, Flex, useToast, IconButton, Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow, PopoverCloseButton, Badge, Image, HStack, useDisclosure, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';
import { getPost, getRelatedPosts, Post, addReaction, getReactions, subscribeToAuthor, unsubscribeFromAuthor, isUserSubscribedToAuthor, getThreadCount, setFeaturedStatus, deletePost } from '../services/postService';
import ViewCodeBlock from '../components/blocks/ViewCodeBlock';
import { useAuth } from '../hooks/useAuth';
import { ChatIcon, DeleteIcon } from '@chakra-ui/icons';
import { FaCube, FaStar } from 'react-icons/fa';
import ThreadsPanel from '../components/ThreadsPanel';
import DOMPurify from 'dompurify';

const RelatedPostCard: React.FC<{ post: Post }> = ({ post }) => {
  const cardBgColor = useColorModeValue('gray.100', 'gray.700');

  return (
    <Link to={`/post/${post.id}`}>
      <Box p={4} bg={cardBgColor} borderRadius="md" height="100%">
        <Heading as="h3" size="sm" mb={2}>
          {post.title}
        </Heading>
        <Text fontSize="xs">{post.authorName}</Text>
      </Box>
    </Link>
  );
};

const PostView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [post, setPost] = useState<Post | null>(null);
  const [relatedPosts, setRelatedPosts] = useState<Post[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [reactions, setReactions] = useState<{ [blockId: string]: { [reactionType: string]: number } }>({});
  const [threadCounts, setThreadCounts] = useState<{ [blockId: string]: number }>({});
  const [expandedCodeBlock, setExpandedCodeBlock] = useState<string | null>(null);
  const { user } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();

  const bgColor = useColorModeValue('white', 'black');
  const textColor = useColorModeValue('black', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const popularityColor = useColorModeValue('blue.600', 'blue.300');
  const iconColor = useColorModeValue('gray.600', 'gray.400');
  const iconHoverColor = useColorModeValue('black', 'white');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const fetchPostAndRelated = async () => {
      if (id) {
        try {
          const fetchedPost = await getPost(id);
          setPost(fetchedPost);
          if (fetchedPost) {
            const related = await getRelatedPosts(id, fetchedPost.authorId, fetchedPost.tags, 3);
            setRelatedPosts(related);
            const fetchedReactions = await getReactions(id);
            setReactions(fetchedReactions);
            const fetchedThreadCounts = await getThreadCount(id);
            setThreadCounts(fetchedThreadCounts);
            if (user) {
              const subscribed = await isUserSubscribedToAuthor(user.uid, fetchedPost.authorId);
              setIsSubscribed(subscribed);
            }
          }
        } catch (error) {
          console.error('Error fetching post:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchPostAndRelated();
  }, [id, user]);

  const handleSubscribe = async () => {
    if (!user) {
      toast({
        title: "Please log in to subscribe",
        description: "You need to be logged in to subscribe to authors.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      navigate('/login');
      return;
    }

    if (!post) return;

    try {
      if (isSubscribed) {
        await unsubscribeFromAuthor(user.uid, post.authorId);
        setIsSubscribed(false);
        toast({
          title: "Unsubscribed successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await subscribeToAuthor(user.uid, post.authorId);
        setIsSubscribed(true);
        toast({
          title: "Subscribed to author successfully",
          description: "You will receive notifications when this author publishes or updates a post.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error updating subscription:', error);
      toast({
        title: "Error updating subscription",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleReaction = async (blockId: string) => {
    if (!user) {
      toast({
        title: "Please log in to react",
        description: "You need to be logged in to react to posts.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      navigate('/login');
      return;
    }

    try {
      await addReaction(id!, blockId, user.uid);
      const updatedReactions = await getReactions(id!);
      setReactions(updatedReactions);
      toast({
        title: "Reaction added",
        description: "Woah, that's cool!",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding reaction:', error);
      toast({
        title: "Error adding reaction",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const getReactionCount = (blockId: string) => {
    const blockReactions = reactions[blockId] || {};
    return Object.values(blockReactions).reduce((sum, count) => sum + count, 0);
  };

  const sanitizeHTML = (html: string) => {
    return {
      __html: DOMPurify.sanitize(html)
    };
  };

  const handleFeaturePost = async () => {
    if (post) {
      try {
        const newFeaturedStatus = !post.featured;
        await setFeaturedStatus(post.id, newFeaturedStatus);
        setPost({ ...post, featured: newFeaturedStatus });
        toast({
          title: newFeaturedStatus ? "Post featured" : "Post unfeatured",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error updating featured status:', error);
        toast({
          title: "Error updating featured status",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleDeletePost = async () => {
    if (!post || !user) return;

    try {
      await deletePost(post.id);
      toast({
        title: "Post deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate('/'); // Redirect to home page after deletion
    } catch (error) {
      console.error('Error deleting post:', error);
      toast({
        title: "Error deleting post",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (!post) {
    return <Box>Post not found</Box>;
  }

  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(date);
  };

  const renderUrlBlock = (content: string) => {
    if (content.includes('youtube.com') || content.includes('youtu.be')) {
      const videoId = content.split('v=')[1] || content.split('/').pop();
      return (
        <AspectRatio ratio={16 / 9}>
          <iframe
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </AspectRatio>
      );
    } else if (content.includes('twitter.com')) {
      return (
        <Box>
          <a href={content} target="_blank" rel="noopener noreferrer">
            {content}
          </a>
        </Box>
      );
    } else {
      return (
        <Box as="a" href={content} target="_blank" rel="noopener noreferrer">
          {content}
        </Box>
      );
    }
  };

  const isAdmin = user && user.email === 'nickbaumann98@gmail.com';

  return (
    <Box maxWidth="800px" margin="auto" mt={8} p={6} bg={bgColor} color={textColor} borderRadius="md" boxShadow="md">
      <VStack spacing={6} align="stretch">
        {post.coverImage && (
          <Box>
            <Image 
              src={post.coverImage} 
              alt={post.title} 
              width="100%" 
              objectFit="cover"
              borderRadius="md"
            />
          </Box>
        )}
        <Heading as="h1" size="2xl" textTransform="uppercase">
          {post.title}
        </Heading>
        <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap">
          <Box borderTop="1px" borderBottom="1px" borderColor={borderColor} py={2} mb={2}>
            <Text fontSize="sm" textTransform="uppercase">
              Published on {formatDate(post.createdAt.toDate())} | By{' '}
              <Link to={`/author/${post.authorId}`} style={{ textDecoration: 'underline' }}>
                {post.authorName || 'Anonymous'}
              </Link>
            </Text>
          </Box>
          <HStack>
            <Button onClick={handleSubscribe} colorScheme={isSubscribed ? "red" : "blue"} size="sm" mb={2}>
              {isSubscribed ? "Unsubscribe" : "Subscribe to Author"}
            </Button>
            {isAdmin && (
              <IconButton
                aria-label={post.featured ? "Unfeature Post" : "Feature Post"}
                icon={<FaStar />}
                onClick={handleFeaturePost}
                colorScheme={post.featured ? "yellow" : "gray"}
                size="sm"
                mb={2}
              />
            )}
            {user && user.uid === post.authorId && (
              <IconButton
                aria-label="Delete Post"
                icon={<DeleteIcon />}
                onClick={onOpen}
                colorScheme="red"
                size="sm"
                mb={2}
              />
            )}
          </HStack>
        </Flex>
        {post.popularityScore !== undefined && (
          <Text fontSize="sm" color={popularityColor}>
            Popularity Score: {post.popularityScore}
          </Text>
        )}
        <Box>
          {post.blocks.map((block, index) => (
            <Box key={block.id} mb={8} position="relative">
              {block.title && <Heading as="h3" size="md" mb={2}>{block.title}</Heading>}
              {block.type === 'text' && (
                <Box dangerouslySetInnerHTML={sanitizeHTML(block.content)} />
              )}
              {block.type === 'code' && (
                <ViewCodeBlock
                  content={block.content}
                  defaultToOutput={JSON.parse(block.content).defaultToOutput}
                  isExpanded={expandedCodeBlock === block.id}
                  onExpand={() => setExpandedCodeBlock(expandedCodeBlock === block.id ? null : block.id)}
                />
              )}
              {block.type === 'url' && renderUrlBlock(block.content)}
              {block.type === 'attachment' && (
                <Box>
                  <a href={block.content} target="_blank" rel="noopener noreferrer">
                    Attachment {index + 1}
                  </a>
                </Box>
              )}
              {block.caption && <Text fontSize="sm" fontStyle="italic" mt={2}>{block.caption}</Text>}
              
              {/* Interaction icons */}
              <Box position="absolute" right="-40px" top="0">
                <VStack spacing={2}>
                  <IconButton
                    aria-label="That's cool"
                    icon={<FaCube />}
                    size="xs"
                    variant="ghost"
                    color={iconColor}
                    _hover={{ color: iconHoverColor }}
                    onClick={() => handleReaction(block.id)}
                  />
                  {getReactionCount(block.id) > 0 && (
                    <Badge colorScheme="green" variant="solid" borderRadius="full">
                      {getReactionCount(block.id)}
                    </Badge>
                  )}
                  <Popover>
                    <PopoverTrigger>
                      <IconButton
                        aria-label="Threads"
                        icon={<ChatIcon />}
                        size="xs"
                        variant="ghost"
                        color={iconColor}
                        _hover={{ color: iconHoverColor }}
                      />
                    </PopoverTrigger>
                    <PopoverContent width="300px">
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody>
                        <ThreadsPanel postId={post.id} blockId={block.id} />
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                  {threadCounts[block.id] > 0 && (
                    <Badge colorScheme="blue" variant="solid" borderRadius="full">
                      {threadCounts[block.id]}
                    </Badge>
                  )}
                </VStack>
              </Box>
            </Box>
          ))}
        </Box>
        {relatedPosts.length > 0 && (
          <Box mt={8}>
            <Heading as="h2" size="lg" mb={4}>
              Related Posts
            </Heading>
            <Grid templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"]} gap={4}>
              {relatedPosts.map((relatedPost) => (
                <GridItem key={relatedPost.id}>
                  <RelatedPostCard post={relatedPost} />
                </GridItem>
              ))}
            </Grid>
          </Box>
        )}
      </VStack>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Post
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this post? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeletePost} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default PostView;