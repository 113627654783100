import React from 'react';
import { Box, Flex, Heading, Button, useColorMode, IconButton } from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { SunIcon, MoonIcon, SearchIcon } from '@chakra-ui/icons';
import { FaUser } from 'react-icons/fa';
import { useAuth } from '../hooks/useAuth';
import Footer from './Footer';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate('/search');
  };

  return (
    <Flex direction="column" minHeight="100vh">
      <Flex 
        as="header" 
        align="center" 
        justify="space-between" 
        wrap="wrap" 
        padding="1rem"
        mb={8}
      >
        <Flex align="center" mr={5}>
          <Heading as="h1" size="lg" letterSpacing={'-.1rem'}>
            <RouterLink to="/">BUILDING BLOCKS</RouterLink>
          </Heading>
        </Flex>

        <Flex align="center">
          {user ? (
            <>
              <Button as={RouterLink} to="/write" variant="outline" size="sm" mr={2}>
                WRITE
              </Button>
              <IconButton
                aria-label="Search"
                icon={<SearchIcon />}
                onClick={handleSearch}
                variant="outline"
                size="sm"
                mr={2}
              />
              <IconButton
                aria-label="Toggle color mode"
                icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                onClick={toggleColorMode}
                variant="outline"
                size="sm"
                mr={2}
              />
              <IconButton
                as={RouterLink}
                to="/profile"
                aria-label="Profile"
                icon={<FaUser />}
                variant="outline"
                size="sm"
              />
            </>
          ) : (
            <>
              <Button as={RouterLink} to="/login" variant="outline" size="sm" mr={2}>
                LOGIN
              </Button>
              <Button as={RouterLink} to="/signup" variant="outline" size="sm" mr={2}>
                SIGN UP
              </Button>
              <IconButton
                aria-label="Search"
                icon={<SearchIcon />}
                onClick={handleSearch}
                variant="outline"
                size="sm"
                mr={2}
              />
              <IconButton
                aria-label="Toggle color mode"
                icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                onClick={toggleColorMode}
                variant="outline"
                size="sm"
              />
            </>
          )}
        </Flex>
      </Flex>

      <Box as="main" flex="1" padding="0 1rem">
        {children}
      </Box>

      <Footer />
    </Flex>
  );
};

export default Layout;