import React, { useState } from 'react';
import { Box, Input, VStack, Text, Link, Heading } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { searchPosts, Post } from '../services/postService';

const Search: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<Post[]>([]);

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term.length >= 3) {
      const results = await searchPosts(term);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  return (
    <Box maxWidth="800px" margin="auto" mt={8}>
      <Heading as="h1" size="2xl" mb={6}>Search Posts</Heading>
      <Input
        placeholder="Search posts..."
        value={searchTerm}
        onChange={handleSearch}
        size="lg"
        mb={6}
      />
      {searchResults.length > 0 ? (
        <VStack spacing={4} align="stretch">
          {searchResults.map((post) => (
            <Box key={post.id} p={4} borderWidth={1} borderRadius="md">
              <Link as={RouterLink} to={`/post/${post.id}`}>
                <Text fontSize="xl" fontWeight="bold">{post.title}</Text>
              </Link>
              <Text fontSize="sm" color="gray.500">
                Published on {post.createdAt.toDate().toLocaleDateString()}
              </Text>
            </Box>
          ))}
        </VStack>
      ) : (
        <Text>No results found. Try a different search term.</Text>
      )}
    </Box>
  );
};

export default Search;