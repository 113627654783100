import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  VStack,
  Image,
  Spinner,
  useColorModeValue,
  Grid,
  GridItem,
  Button,
  HStack,
  useToast,
  Flex,
  Badge,
  Divider,
  Link as ChakraLink,
} from '@chakra-ui/react';
import { getAuthorDetails, getUserPosts, Post, subscribeToAuthor, unsubscribeFromAuthor, isUserSubscribedToAuthor, Author } from '../services/postService';
import { useAuth } from '../hooks/useAuth';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const POSTS_PER_PAGE = 6;

const AuthorProfile: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [author, setAuthor] = useState<Author | null>(null);
  const [posts, setPosts] = useState<Post[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);

  const { user } = useAuth();
  const toast = useToast();

  const bgColor = useColorModeValue('white', 'black');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  useEffect(() => {
    const fetchAuthorAndPosts = async () => {
      if (id) {
        try {
          const authorData = await getAuthorDetails(id);
          setAuthor(authorData);

          const authorPosts = await getUserPosts(id);
          setPosts(authorPosts.slice(0, POSTS_PER_PAGE));
          setHasMore(authorPosts.length > POSTS_PER_PAGE);

          if (user) {
            const subscribed = await isUserSubscribedToAuthor(user.uid, id);
            setIsSubscribed(subscribed);
          }
        } catch (error) {
          console.error('Error fetching author data:', error);
          toast({
            title: 'Error',
            description: 'Failed to load author profile',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchAuthorAndPosts();
  }, [id, user, toast]);

  const loadMorePosts = async () => {
    if (id) {
      const nextPage = page + 1;
      const startIndex = (nextPage - 1) * POSTS_PER_PAGE;
      const endIndex = startIndex + POSTS_PER_PAGE;

      const newPosts = await getUserPosts(id);
      const paginatedPosts = newPosts.slice(startIndex, endIndex);

      setPosts(prevPosts => [...prevPosts, ...paginatedPosts]);
      setPage(nextPage);
      setHasMore(endIndex < newPosts.length);
    }
  };

  const handleSubscribe = async () => {
    if (user && author) {
      setIsSubscribing(true);
      try {
        if (isSubscribed) {
          await unsubscribeFromAuthor(user.uid, author.id);
          setIsSubscribed(false);
          toast({
            title: 'Unsubscribed',
            description: `You have unsubscribed from ${author.displayName}`,
            status: 'info',
            duration: 3000,
            isClosable: true,
          });
        } else {
          await subscribeToAuthor(user.uid, author.id);
          setIsSubscribed(true);
          toast({
            title: 'Subscribed',
            description: `You have subscribed to ${author.displayName}`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error('Error subscribing/unsubscribing:', error);
        toast({
          title: 'Error',
          description: 'Failed to update subscription',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsSubscribing(false);
      }
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (!author) {
    return <Box>Author not found</Box>;
  }

  return (
    <Box maxWidth="800px" margin="auto" mt={8} p={6} bg={bgColor} color={textColor} borderRadius="md" boxShadow="md">
      <VStack spacing={6} align="stretch">
        <Flex direction={{ base: 'column', md: 'row' }} alignItems="center" justifyContent="space-between">
          <HStack spacing={6} align="center" mb={{ base: 4, md: 0 }}>
            {author.profilePicture && (
              <Image
                src={author.profilePicture}
                alt={author.displayName}
                borderRadius="full"
                boxSize="150px"
                objectFit="cover"
              />
            )}
            <VStack align="start" spacing={2}>
              <Heading as="h1" size="2xl">
                {author.displayName}
              </Heading>
              <HStack>
                <Badge colorScheme="blue">Posts: {author.totalPosts}</Badge>
                <Badge colorScheme="green">Subscribers: {author.subscriberCount}</Badge>
              </HStack>
              <Text fontSize="sm" color="gray.500">
                Joined: {author.joinDate.toDate().toLocaleDateString()}
              </Text>
            </VStack>
          </HStack>
          {user && user.uid !== author.id && (
            <Button
              onClick={handleSubscribe}
              isLoading={isSubscribing}
              colorScheme={isSubscribed ? 'red' : 'blue'}
              size="lg"
            >
              {isSubscribed ? 'Unsubscribe' : 'Subscribe'}
            </Button>
          )}
        </Flex>

        <Divider />

        {author.bio && (
          <Box>
            <Heading as="h2" size="md" mb={2}>
              About
            </Heading>
            <Text>{author.bio}</Text>
          </Box>
        )}

        {author.personalLinks && author.personalLinks.length > 0 && (
          <Box>
            <Heading as="h2" size="md" mb={2}>
              Personal Links
            </Heading>
            <VStack align="start" spacing={2}>
              {author.personalLinks.map((link) => (
                <ChakraLink href={link.url} isExternal key={link.id}>
                  {link.description} <ExternalLinkIcon mx="2px" />
                </ChakraLink>
              ))}
            </VStack>
          </Box>
        )}

        <Divider />

        <Heading as="h2" size="xl" mt={8}>
          Posts
        </Heading>
        <Grid templateColumns="repeat(auto-fill, minmax(250px, 1fr))" gap={6}>
          {posts.map((post) => (
            <GridItem key={post.id}>
              <Link to={`/post/${post.id}`}>
                <Box p={4} borderWidth={1} borderRadius="md" height="100%" borderColor={borderColor} _hover={{ boxShadow: 'md' }}>
                  <Heading as="h3" size="md" mb={2}>
                    {post.title}
                  </Heading>
                  <Text fontSize="sm" color="gray.500">
                    {new Date(post.createdAt.seconds * 1000).toLocaleDateString()}
                  </Text>
                </Box>
              </Link>
            </GridItem>
          ))}
        </Grid>
        {hasMore && (
          <Button onClick={loadMorePosts} mt={4} colorScheme="blue" width="full">
            Load More Posts
          </Button>
        )}
      </VStack>
    </Box>
  );
};

export default AuthorProfile;