import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Write from './pages/Write';
import PostView from './pages/PostView';
import Search from './pages/Search';
import AuthorProfile from './pages/AuthorProfile';
import Profile from './pages/Profile';
import Layout from './components/Layout';
import { AuthProvider } from './AuthContext';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/write" element={<ProtectedRoute><Write /></ProtectedRoute>} />
              <Route path="/write/:id" element={<ProtectedRoute><Write /></ProtectedRoute>} />
              <Route path="/post/:id" element={<PostView />} />
              <Route path="/search" element={<Search />} />
              <Route path="/author/:id" element={<AuthorProfile />} />
              <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            </Routes>
          </Layout>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
