import { extendTheme, ThemeConfig } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  colors: {
    brand: {
      50: "#f0f9ff",
      100: "#e0f2fe",
      200: "#bae6fd",
      300: "#7dd3fc",
      400: "#38bdf8",
      500: "#0ea5e9",
      600: "#0284c7",
      700: "#0369a1",
      800: "#075985",
      900: "#0c4a6e",
    },
  },
  fonts: {
    heading: "Inter, sans-serif",
    body: "Inter, sans-serif",
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: "bold",
        borderRadius: 0,
      },
      variants: {
        outline: (props: any) => ({
          bg: "transparent",
          color: props.colorMode === "dark" ? "white" : "black",
          border: "2px solid",
          borderColor: props.colorMode === "dark" ? "white" : "black",
          _hover: {
            bg: props.colorMode === "dark" ? "white" : "black",
            color: props.colorMode === "dark" ? "black" : "white",
          },
        }),
        solid: (props: any) => ({
          bg: props.colorMode === "dark" ? "white" : "black",
          color: props.colorMode === "dark" ? "black" : "white",
          _hover: {
            bg: props.colorMode === "dark" ? "black" : "white",
            color: props.colorMode === "dark" ? "white" : "black",
            border: "2px solid",
            borderColor: props.colorMode === "dark" ? "white" : "black",
          },
        }),
      },
    },
    Heading: {
      baseStyle: {
        fontWeight: "bold",
      },
    },
  },
  styles: {
    global: (props: any) => ({
      body: {
        bg: props.colorMode === "dark" ? "black" : "white",
        color: props.colorMode === "dark" ? "white" : "black",
      },
    }),
  },
});

export default theme;