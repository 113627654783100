import React, { useState, useEffect } from 'react';
import { VStack, Text, Input, Button, useColorModeValue, Spinner, Avatar, Flex, Box } from '@chakra-ui/react';
import { getThreadsForBlock, createThread, addReplyToThread, Thread, Reply } from '../services/postService';
import { useAuth } from '../hooks/useAuth';
import { Timestamp } from 'firebase/firestore';

interface ThreadsPanelProps {
  postId: string;
  blockId: string;
}

const ThreadsPanel: React.FC<ThreadsPanelProps> = ({ postId, blockId }) => {
  const [threads, setThreads] = useState<Thread[]>([]);
  const [newThreadContent, setNewThreadContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.800', 'gray.200');

  useEffect(() => {
    const fetchThreads = async () => {
      try {
        const fetchedThreads = await getThreadsForBlock(postId, blockId);
        setThreads(fetchedThreads);
      } catch (error) {
        console.error('Error fetching threads:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchThreads();
  }, [postId, blockId]);

  const handleCreateThread = async () => {
    if (!user) return;

    try {
      const newThread: Omit<Thread, 'id' | 'replies'> = {
        postId,
        blockId,
        authorId: user.uid,
        content: newThreadContent,
        createdAt: Timestamp.now()
      };
      const threadId = await createThread(newThread);
      setThreads([{ ...newThread, id: threadId, replies: [] }, ...threads]);
      setNewThreadContent('');
    } catch (error) {
      console.error('Error creating thread:', error);
    }
  };

  const handleAddReply = async (threadId: string, replyContent: string) => {
    if (!user) return;

    try {
      const newReply: Omit<Reply, 'id'> = {
        authorId: user.uid,
        content: replyContent,
        createdAt: Timestamp.now()
      };
      await addReplyToThread(threadId, newReply);
      setThreads(threads.map(thread => 
        thread.id === threadId 
          ? { ...thread, replies: Array.isArray(thread.replies) ? [...thread.replies, newReply as Reply] : [newReply as Reply] }
          : thread
      ));
    } catch (error) {
      console.error('Error adding reply:', error);
    }
  };

  if (isLoading) {
    return <Spinner size="sm" />;
  }

  return (
    <VStack spacing={4} align="stretch" bg={bgColor} color={textColor}>
      <Input 
        placeholder="Start a new thread..." 
        value={newThreadContent}
        onChange={(e) => setNewThreadContent(e.target.value)}
        size="sm"
      />
      <Button 
        onClick={handleCreateThread}
        isDisabled={!user || newThreadContent.trim() === ''}
        size="sm"
        width="full"
      >
        Post
      </Button>
      {threads.map((thread) => (
        <Box key={thread.id} borderWidth={1} borderRadius="md" p={2} borderColor={borderColor}>
          <Flex alignItems="center" mb={2}>
            <Avatar size="xs" name={thread.authorId} mr={2} />
            <Text fontSize="xs" fontWeight="bold">{thread.authorId}</Text>
          </Flex>
          <Text fontSize="sm" mb={2}>{thread.content}</Text>
          {Array.isArray(thread.replies) && thread.replies.map((reply, index) => (
            <Box key={index} ml={4} mt={1}>
              <Flex alignItems="center" mb={1}>
                <Avatar size="2xs" name={reply.authorId} mr={1} />
                <Text fontSize="xs" fontWeight="bold">{reply.authorId}</Text>
              </Flex>
              <Text fontSize="xs">{reply.content}</Text>
            </Box>
          ))}
          <Input 
            placeholder="Reply..." 
            size="xs"
            mt={2}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleAddReply(thread.id, (e.target as HTMLInputElement).value);
                (e.target as HTMLInputElement).value = '';
              }
            }}
          />
        </Box>
      ))}
    </VStack>
  );
};

export default ThreadsPanel;