import { db } from '../firebase';
import { collection, addDoc, updateDoc, deleteDoc, doc, getDoc, getDocs, query, where, Timestamp, orderBy, limit, increment, setDoc } from 'firebase/firestore';

export interface Block {
  id: string;
  type: 'text' | 'code' | 'url' | 'attachment';
  content: string;
  order: number;
  title?: string;
  caption?: string;
  reactions?: { [userId: string]: string[] };
}

export interface Post {
  id: string;
  title: string;
  authorId: string;
  authorName?: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  published: boolean;
  blocks: Block[];
  coverImage: string;
  featured?: boolean;
  featuredOrder?: number;
  likes?: number;
  tags: string[];
  popularityScore?: number;
}

export interface PersonalLink {
  id: string;
  description: string;
  url: string;
}

export interface Author {
  id: string;
  displayName: string;
  bio?: string;
  profilePicture?: string;
  personalLinks?: PersonalLink[];
  joinDate: Timestamp;
  totalPosts: number;
  subscriberCount: number;
}

export interface Thread {
  id: string;
  postId: string;
  blockId: string;
  authorId: string;
  content: string;
  createdAt: Timestamp;
  replies: Reply[];
}

export interface Reply {
  id: string;
  authorId: string;
  content: string;
  createdAt: Timestamp;
}

export interface Subscription {
  id: string;
  userId: string;
  type: 'post_update' | 'author_publish';
  targetId: string;
  createdAt: Timestamp;
}

const postsCollection = collection(db, 'posts');
const threadsCollection = collection(db, 'threads');
const subscriptionsCollection = collection(db, 'subscriptions');

export const createPost = async (post: Omit<Post, 'id'>): Promise<string> => {
  const docRef = await addDoc(postsCollection, {
    ...post,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
    published: false
  });
  return docRef.id;
};

export const publishPost = async (postId: string, authorId: string): Promise<void> => {
  const postRef = doc(db, 'posts', postId);
  const authorName = await getAuthorName(authorId);
  await updateDoc(postRef, {
    published: true,
    authorName,
    updatedAt: Timestamp.now()
  });
};

export const getPost = async (id: string): Promise<Post | null> => {
  const docRef = doc(db, 'posts', id);
  const docSnap = await getDoc(docRef);
  
  if (docSnap.exists()) {
    const postData = { id: docSnap.id, ...docSnap.data() } as Post;
    postData.authorName = await getAuthorName(postData.authorId);
    return postData;
  } else {
    return null;
  }
};

export const getAuthorName = async (authorId: string): Promise<string> => {
  const userDocRef = doc(db, 'users', authorId);
  const userDocSnap = await getDoc(userDocRef);
  
  if (userDocSnap.exists()) {
    const userData = userDocSnap.data();
    return userData.displayName || 'Anonymous';
  } else {
    return 'Anonymous';
  }
};

export const updatePost = async (id: string, post: Partial<Post>): Promise<void> => {
  const docRef = doc(db, 'posts', id);
  await updateDoc(docRef, post);
};

export const deletePost = async (id: string): Promise<void> => {
  const docRef = doc(db, 'posts', id);
  await deleteDoc(docRef);
};

export const getUserPosts = async (userId: string): Promise<Post[]> => {
  const q = query(postsCollection, where("authorId", "==", userId), where("published", "==", true), orderBy("createdAt", "desc"));
  const querySnapshot = await getDocs(q);
  const posts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Post));
  
  // Fetch author names for all posts
  for (let post of posts) {
    post.authorName = await getAuthorName(post.authorId);
  }
  
  return posts;
};

export const getAllPosts = async (): Promise<Post[]> => {
  const querySnapshot = await getDocs(postsCollection);
  const posts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Post));
  
  // Fetch author names for all posts
  for (let post of posts) {
    post.authorName = await getAuthorName(post.authorId);
  }
  
  return posts;
};

export const saveDraft = async (post: Omit<Post, 'id'>): Promise<string> => {
  return createPost({ ...post, published: false });
};

export const getDrafts = async (userId: string): Promise<Post[]> => {
  const q = query(postsCollection, where("authorId", "==", userId), where("published", "==", false));
  const querySnapshot = await getDocs(q);
  const posts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Post));
  
  // Fetch author names for all drafts
  for (let post of posts) {
    post.authorName = await getAuthorName(post.authorId);
  }
  
  return posts;
};

export const searchPosts = async (searchTerm: string): Promise<Post[]> => {
  const q = query(
    postsCollection,
    where("published", "==", true),
    where("title", ">=", searchTerm),
    where("title", "<=", searchTerm + '\uf8ff'),
    orderBy("title"),
    limit(10)
  );
  const querySnapshot = await getDocs(q);
  const posts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Post));
  
  // Fetch author names for all searched posts
  for (let post of posts) {
    post.authorName = await getAuthorName(post.authorId);
  }
  
  return posts;
};

export const getRecentPosts = async (count: number, oldest: boolean = false): Promise<Post[]> => {
  const q = query(
    postsCollection,
    where("published", "==", true),
    orderBy("createdAt", oldest ? "asc" : "desc"),
    limit(count)
  );
  const querySnapshot = await getDocs(q);
  const posts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Post));
  
  // Fetch author names for all recent posts
  for (let post of posts) {
    post.authorName = await getAuthorName(post.authorId);
  }
  
  return posts;
};

export const getFeaturedPosts = async (count: number): Promise<Post[]> => {
  const q = query(
    postsCollection,
    where("published", "==", true),
    where("featured", "==", true),
    orderBy("featuredOrder", "desc"),
    limit(count)
  );
  const querySnapshot = await getDocs(q);
  const posts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Post));
  
  // Fetch author names for all featured posts
  for (let post of posts) {
    post.authorName = await getAuthorName(post.authorId);
  }
  
  return posts;
};

export const getPopularPosts = async (count: number): Promise<Post[]> => {
  const q = query(
    postsCollection,
    where("published", "==", true),
    orderBy("popularityScore", "desc"),
    limit(count)
  );
  const querySnapshot = await getDocs(q);
  const posts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Post));
  
  // Fetch author names for all popular posts
  for (let post of posts) {
    post.authorName = await getAuthorName(post.authorId);
  }
  
  return posts;
};

export const getPostsByTag = async (tag: string, count: number = 10): Promise<Post[]> => {
  const q = query(
    postsCollection,
    where("published", "==", true),
    where("tags", "array-contains", tag),
    orderBy("createdAt", "desc"),
    limit(count)
  );
  const querySnapshot = await getDocs(q);
  const posts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Post));
  
  // Fetch author names for all posts
  for (let post of posts) {
    post.authorName = await getAuthorName(post.authorId);
  }
  
  return posts;
};

export const getRelatedPosts = async (currentPostId: string, authorId: string, tags: string[], count: number = 3): Promise<Post[]> => {
  // First, try to find posts with matching tags
  let relatedPosts: Post[] = [];
  for (const tag of tags) {
    const tagPosts = await getPostsByTag(tag, count);
    relatedPosts = [...relatedPosts, ...tagPosts.filter(post => post.id !== currentPostId)];
    if (relatedPosts.length >= count) break;
  }

  // If we don't have enough related posts by tags, add posts by the same author
  if (relatedPosts.length < count) {
    const authorPosts = await getUserPosts(authorId);
    relatedPosts = [
      ...relatedPosts,
      ...authorPosts.filter(post => post.id !== currentPostId && !relatedPosts.some(rp => rp.id === post.id))
    ];
  }

  // Limit the results to the requested count
  return relatedPosts.slice(0, count);
};

export const getAuthorDetails = async (authorId: string): Promise<Author | null> => {
  const userDocRef = doc(db, 'users', authorId);
  const userDocSnap = await getDoc(userDocRef);
  
  if (userDocSnap.exists()) {
    const userData = userDocSnap.data();
    
    // Get total posts
    const postsQuery = query(postsCollection, where("authorId", "==", authorId), where("published", "==", true));
    const postsSnapshot = await getDocs(postsQuery);
    const totalPosts = postsSnapshot.size;

    // Get subscriber count
    const subscribersQuery = query(subscriptionsCollection, where("targetId", "==", authorId), where("type", "==", "author_publish"));
    const subscribersSnapshot = await getDocs(subscribersQuery);
    const subscriberCount = subscribersSnapshot.size;

    return {
      id: userDocSnap.id,
      displayName: userData.displayName || 'Anonymous',
      bio: userData.bio,
      profilePicture: userData.profilePicture,
      personalLinks: userData.personalLinks || [],
      joinDate: userData.createdAt || Timestamp.now(),
      totalPosts,
      subscriberCount
    };
  } else {
    return null;
  }
};

export const updateAuthorProfile = async (authorId: string, profileData: Partial<Author>): Promise<void> => {
  const userDocRef = doc(db, 'users', authorId);
  try {
    console.log('Updating author profile with data:', profileData);
    const docSnap = await getDoc(userDocRef);
    if (!docSnap.exists()) {
      // If the document doesn't exist, create it
      await setDoc(userDocRef, {
        ...profileData,
        id: authorId,
        joinDate: Timestamp.now(),
        totalPosts: 0,
        subscriberCount: 0
      });
      console.log('Author profile created successfully');
    } else {
      // If the document exists, update it
      await updateDoc(userDocRef, profileData);
      console.log('Author profile updated successfully');
    }
  } catch (error) {
    console.error('Error updating author profile:', error);
    if (error instanceof Error) {
      console.error('Error message:', error.message);
      console.error('Error stack:', error.stack);
    }
    throw error; // Re-throw the error so it can be caught in the component
  }
};

export const addPersonalLink = async (authorId: string, link: Omit<PersonalLink, 'id'>): Promise<void> => {
  const userDocRef = doc(db, 'users', authorId);
  const userDocSnap = await getDoc(userDocRef);
  
  if (userDocSnap.exists()) {
    const userData = userDocSnap.data();
    const personalLinks = userData.personalLinks || [];
    const newLink = { ...link, id: Date.now().toString() };
    personalLinks.push(newLink);
    await updateDoc(userDocRef, { personalLinks });
  }
};

export const removePersonalLink = async (authorId: string, linkId: string): Promise<void> => {
  const userDocRef = doc(db, 'users', authorId);
  const userDocSnap = await getDoc(userDocRef);
  
  if (userDocSnap.exists()) {
    const userData = userDocSnap.data();
    const personalLinks = userData.personalLinks || [];
    const updatedLinks = personalLinks.filter((link: PersonalLink) => link.id !== linkId);
    await updateDoc(userDocRef, { personalLinks: updatedLinks });
  }
};

export const createThread = async (thread: Omit<Thread, 'id' | 'replies'>): Promise<string> => {
  const docRef = await addDoc(threadsCollection, { ...thread, replies: [] });
  return docRef.id;
};

export const getThreadsForBlock = async (postId: string, blockId: string): Promise<Thread[]> => {
  const q = query(
    threadsCollection,
    where("postId", "==", postId),
    where("blockId", "==", blockId),
    orderBy("createdAt", "desc")
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Thread));
};

export const addReplyToThread = async (threadId: string, reply: Omit<Reply, 'id'>): Promise<string> => {
  const threadRef = doc(db, 'threads', threadId);
  const newReply = { ...reply, id: Date.now().toString() };
  await updateDoc(threadRef, {
    replies: increment(1),
    [`replies.${newReply.id}`]: newReply
  });
  return newReply.id;
};

export const createSubscription = async (subscription: Omit<Subscription, 'id'>): Promise<string> => {
  const docRef = await addDoc(subscriptionsCollection, subscription);
  return docRef.id;
};

export const removeSubscription = async (subscriptionId: string): Promise<void> => {
  await deleteDoc(doc(db, 'subscriptions', subscriptionId));
};

export const getSubscriptionsForUser = async (userId: string): Promise<Subscription[]> => {
  const q = query(subscriptionsCollection, where("userId", "==", userId));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Subscription));
};

export const setFeaturedStatus = async (postId: string, featured: boolean): Promise<void> => {
  const postRef = doc(db, 'posts', postId);
  const updateData: Partial<Post> = { featured };
  
  if (featured) {
    const featuredPostsQuery = query(
      collection(db, 'posts'),
      where('featured', '==', true),
      orderBy('featuredOrder', 'desc'),
      limit(1)
    );
    const featuredPostsSnapshot = await getDocs(featuredPostsQuery);
    const lastFeaturedPost = featuredPostsSnapshot.docs[0];
    updateData.featuredOrder = lastFeaturedPost ? (lastFeaturedPost.data().featuredOrder || 0) + 1 : 0;
  } else {
    updateData.featuredOrder = 0; // Set to 0 instead of null when unfeaturing
  }

  await updateDoc(postRef, updateData);
};

export const updatePopularityScore = async (postId: string): Promise<void> => {
  const postRef = doc(db, 'posts', postId);
  const postDoc = await getDoc(postRef);
  
  if (postDoc.exists()) {
    const post = postDoc.data() as Post;
    const blocks = post.blocks || [];
    
    // Count total reactions
    const totalReactions = blocks.reduce((sum, block) => {
      const blockReactions = block.reactions || {};
      return sum + Object.values(blockReactions).reduce((blockSum, userReactions) => blockSum + userReactions.length, 0);
    }, 0);
    
    // Count threads (comments)
    const threadsQuery = query(threadsCollection, where("postId", "==", postId));
    const threadsSnapshot = await getDocs(threadsQuery);
    const threadCount = threadsSnapshot.size;
    
    // Calculate popularity score (you can adjust this formula as needed)
    const popularityScore = totalReactions * 2 + threadCount * 3;
    
    // Update the post with the new popularity score
    await updateDoc(postRef, { popularityScore });
  }
};

export const addReaction = async (postId: string, blockId: string, userId: string): Promise<void> => {
  const postRef = doc(db, 'posts', postId);
  const postDoc = await getDoc(postRef);
  
  if (postDoc.exists()) {
    const post = postDoc.data() as Post;
    const blocks = post.blocks || [];
    const blockIndex = blocks.findIndex(block => block.id === blockId);
    
    if (blockIndex !== -1) {
      const block = blocks[blockIndex];
      const reactions = block.reactions || {};
      const userReactions = reactions[userId] || [];
      
      if (!userReactions.includes('cool')) {
        userReactions.push('cool');
        reactions[userId] = userReactions;
        blocks[blockIndex] = { ...block, reactions };
        
        await updateDoc(postRef, { blocks });
      }
    }
  }
};

export const getReactions = async (postId: string): Promise<{ [blockId: string]: { [reactionType: string]: number } }> => {
  const postRef = doc(db, 'posts', postId);
  const postDoc = await getDoc(postRef);
  
  if (postDoc.exists()) {
    const post = postDoc.data() as Post;
    const blocks = post.blocks || [];
    
    const reactionsMap: { [blockId: string]: { [reactionType: string]: number } } = {};
    
    blocks.forEach(block => {
      if (block.reactions) {
        const blockReactions: { [reactionType: string]: number } = {};
        Object.values(block.reactions).forEach(userReactions => {
          userReactions.forEach(reaction => {
            blockReactions[reaction] = (blockReactions[reaction] || 0) + 1;
          });
        });
        reactionsMap[block.id] = blockReactions;
      }
    });
    
    return reactionsMap;
  }
  
  return {};
};

export const subscribeToAuthor = async (userId: string, authorId: string): Promise<string> => {
  const subscription: Omit<Subscription, 'id'> = {
    userId,
    type: 'author_publish',
    targetId: authorId,
    createdAt: Timestamp.now()
  };
  
  const docRef = await addDoc(subscriptionsCollection, subscription);
  return docRef.id;
};

export const unsubscribeFromAuthor = async (userId: string, authorId: string): Promise<void> => {
  const q = query(
    subscriptionsCollection,
    where("userId", "==", userId),
    where("targetId", "==", authorId),
    where("type", "==", "author_publish")
  );
  const querySnapshot = await getDocs(q);
  
  querySnapshot.forEach(async (doc) => {
    await deleteDoc(doc.ref);
  });
};

export const isUserSubscribedToAuthor = async (userId: string, authorId: string): Promise<boolean> => {
  const q = query(
    subscriptionsCollection,
    where("userId", "==", userId),
    where("targetId", "==", authorId),
    where("type", "==", "author_publish"),
    limit(1)
  );
  const querySnapshot = await getDocs(q);
  
  return !querySnapshot.empty;
};

export const logAuthorDetails = async (authorId: string): Promise<void> => {
  const userDocRef = doc(db, 'users', authorId);
  try {
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      console.log('Current author details:', userDocSnap.data());
    } else {
      console.log('No author found with ID:', authorId);
    }
  } catch (error) {
    console.error('Error fetching author details for logging:', error);
  }
};

export const getSubscribedAuthors = async (userId: string): Promise<Author[]> => {
  const subscribedAuthors: Author[] = [];
  
  // First, get all the author subscriptions for the user
  const subscriptionsQuery = query(
    subscriptionsCollection,
    where("userId", "==", userId),
    where("type", "==", "author_publish")
  );
  const subscriptionsSnapshot = await getDocs(subscriptionsQuery);
  
  // For each subscription, fetch the author details
  for (const subscriptionDoc of subscriptionsSnapshot.docs) {
    const subscription = subscriptionDoc.data() as Subscription;
    const authorDetails = await getAuthorDetails(subscription.targetId);
    if (authorDetails) {
      subscribedAuthors.push(authorDetails);
    }
  }
  
  return subscribedAuthors;
};

export const getThreadCount = async (postId: string): Promise<{ [blockId: string]: number }> => {
  const q = query(threadsCollection, where("postId", "==", postId));
  const querySnapshot = await getDocs(q);
  
  const threadCounts: { [blockId: string]: number } = {};
  
  querySnapshot.forEach((doc) => {
    const thread = doc.data() as Thread;
    threadCounts[thread.blockId] = (threadCounts[thread.blockId] || 0) + 1;
  });
  
  return threadCounts;
};