import React from 'react';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';

const Footer: React.FC = () => {
  const textColor = useColorModeValue('gray.600', 'gray.400');

  return (
    <Box as="footer" width="100%" py={4} textAlign="center">
      <Text fontSize="sm" color={textColor}>
        Building Blocks 2024 • Designed by Nick Baumann
      </Text>
    </Box>
  );
};

export default Footer;